@media only screen and (max-width: 500px) {
  .dapp-topbar {
    padding: 14px 11px 0 2px;
    background: transparent;

    .top-menu {
      width: 100%;
      justify-content: space-around;
      flex-wrap: wrap;

      .menu-item {
        margin-left: 0;
      }
    }

    .menu-btn {
      margin-top: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 2px solid #1CFFAF;
      border-radius: 10px;
      cursor: pointer;
      
      width: 97px;
      height: 30px;
      font-size: 16px;
      color: #FFF;
    }
    
    .connect-wallet {
      height: 30px;
      font-size: 12px;
      padding: 0 4px;
    }
  }

  // .MuiPaper-root .MuiToolbar-root {
  //   flex-direction: column;
  // }
}