.dapp-topbar {
  width: 100%;
  padding: 5px 35px 11px 65px;
  align-items: center;
  justify-content: space-between !important;
  background: rgba(0, 0, 0, .57);
  button {
    white-space: nowrap !important;
    overflow: hidden !important;
    margin: 6px !important;
    min-width: fit-content !important;
    &#hamburger {
      padding: 11px !important;
    }
    &.toggle-button {
      padding: 9.5px !important;
    }
  }
  .logo {
    width: 174px;
    height: 33px;
    cursor: pointer;
  }

  .top-menu {
    height: 100%;

    .menu-item {
      margin-left: 78px;
      font-size: 24px;
      font-family: Myriad Pro;
      font-weight: 400;
      color: #FFFFFF;
      text-shadow: 0px 0px 12px rgba(206, 29, 214, 0.59);
    }

    .menu-btn {
      margin-left: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 156px;
      height: 42px;
      border: 2px solid #1CFFAF;
      border-radius: 10px;
      cursor: pointer;
    }

    .about {
      text-shadow: 0px 0px 12px rgba(34, 253, 164, 0.59);
    }
    .bond {
      text-shadow: 0px 0px 12px rgba(206, 29, 214, 0.59);
    }
    .faq {
      text-shadow: 0px 0px 12px rgba(0, 90, 255, 0.81);
    }
  }
  
  .connect-wallet {
    height: 42px;
    padding: 0 8px;
    background: #CC21DB;
    border: 2px solid #CC21DB;
    border-radius: 10px;

    font-size: 24px;
    font-family: Myriad Pro;
    font-weight: 400;
    color: #FFFFFF;
    cursor: pointer;
  }
}

.MuiLink-root.active {
  // color: #000 !important;
  text-decoration: none !important;
  // background-color: #E3C990;
}

.connect-wallet {
  margin-left: 0 !important;
}
